import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mcruz/Documents/Developer/gatsby/appuro-jps-steelsolutions-pt/src/components/layout.js";
import MdxLayout from "../components/mdxLayout.js";
import MdxCoverLayout from "../components/mdxCoverLayout.js";
import Seo from '../components/seo.js';
import ogImage from '../../static/og-image/ogg-image-default.png';
import GallerySection1 from '../components/sections/gallerySection1.js';
import GallerySection2 from '../components/sections/gallerySection2.js';
import GallerySection3 from '../components/sections/gallerySection3.js';
import GallerySection4 from '../components/sections/gallerySection4.js';
import GallerySection5 from '../components/sections/gallerySection5.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Portfólio" description="JPS Steel Solutions é uma Serralharia especializada em construções de aço inox." image={'https://www.jps-steelsolutions.pt' + ogImage} siteUrl={'https://www.jps-steelsolutions.pt' + props.pageContext.frontmatter.path} mdxType="Seo" />
    <MdxCoverLayout mdxType="MdxCoverLayout" />
    <MdxLayout mdxType="MdxLayout">
      <h1>{props.pageContext.frontmatter.title}</h1>
      <p><strong parentName="p">{`Gradeamento`}</strong></p>
      <GallerySection1 mdxType="GallerySection1" />
      <p><strong parentName="p">{`Escadas`}</strong></p>
      <GallerySection2 mdxType="GallerySection2" />
      <p><strong parentName="p">{`Portão`}</strong></p>
      <GallerySection3 mdxType="GallerySection3" />
    </MdxLayout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      